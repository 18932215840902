import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApplicationService } from '../core/service/application.service';
import { UniversalSessionService } from '../core/service/universalSession.service';
import { UserCredentials } from '../models/user-credentials';
import { DashboardService } from '../service/dashboard/dashboard.service';
import { AlertService } from '../service/shared/alert.service';
import { UniversalTransmitUtils } from '../shared/Util/universal-transmit-utils';
import { TransmitUniversalService } from '../service/universal/transmit-universal.service';
import jwt_decode from 'jwt-decode';
import { SpinnerService } from '../service/shared/spinner.service';
import { environment } from 'src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { SsoUtils } from '../shared/Util/sso-utils';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit, AfterViewInit {
  id: string;
  universalId;
  public userCompanyNames = new Set();
  public userCompanyNamesMap = new Map();
  public isMobile: boolean = false;

  constructor(private https: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: UniversalSessionService,
    private service: DashboardService,
    private appService: ApplicationService,
    private transmitUtils: UniversalTransmitUtils,
    private alertService: AlertService,
    private transmitService: TransmitUniversalService,
    private spinnerService: SpinnerService,
    private utils: SsoUtils
  ) { }

  ngOnInit(): void {
    this.isMobile = this.utils.isMobile();
    this.route.queryParams.subscribe((params) => {
      this.id = params.code;
    })
    this.getCredentials();
  }

  ngAfterViewInit(): void {
    ///Initialize transmit call if it is digital butler
    if (this.sessionService.isButlerApp()) {
      this.transmitService.initializeTransmit(environment.transmitUrl, undefined, this,
        environment.univ_portal_transmitJourney);
    }
  }

  getCredentials(): any {
    let userCredentials: UserCredentials;
    this.sessionService.getSessionData('mobileLinkedcredentials');
    let disabledCredentials: any = {}

    this.service.getAllCredentialsByLogin(true, this.id).pipe(
      map((data: any) => {
        this.service.setData(data);
        // set the session for the data so at the time of refresh we should have the authID
        sessionStorage.setItem("credData", JSON.stringify(data));
        console.warn(data);
        sessionStorage.setItem("segment", data?.segment);
        userCredentials = new UserCredentials(data);
        this.service.emailId = data.email;
        if (data.linkedCredentialsJson === undefined && this.isMobile) {
          this.service.classicView = true;
        }
        if (data.linkedCredentialsJson && data.linkedCredentialsJson !== undefined) {
          const { userPreferences } = JSON.parse(data.linkedCredentialsJson);
          if (userPreferences !== undefined) {
            const { isClassicLayout } = JSON.parse(userPreferences);
            this.service.classicView = isClassicLayout;
          } else {
              this.service.classicView = false;
          }
          const linkedCredentialsJson = JSON.parse(data.linkedCredentialsJson);
          const acknowledgements = linkedCredentialsJson?.acknowledgements ? JSON.parse(linkedCredentialsJson?.acknowledgements) : undefined;
          const fraudProtectionAcknowledgement = acknowledgements?.fraudProtectionAcknowledgement || 'notavailable';
          const erpSplashValue = acknowledgements?.erpInfo || 'notavailable';
          const erpConnectValue = acknowledgements?.erpConnectInfo || 'notavailable';
          const accountVerfSplashVal = acknowledgements?.accountVerfSplash || 'notavailable';
          this.service.setErpSplash(erpSplashValue);
          this.service.setErpConnectInfo(erpConnectValue);
          this.service.setAcctVerfSplash(accountVerfSplashVal);
          if (acknowledgements) {
            this.service.mergedAcknowledgement = acknowledgements;
            sessionStorage.setItem('ack', JSON.stringify(this.service.mergedAcknowledgement));
          }
        } else {
          this.service.setErpSplash('notavailable');
          this.service.setErpConnectInfo('notavailable');
          this.service.setAcctVerfSplash('notavailable');
          // this.service.setFraudPreventionSplash('notavailable');
        }

        this.service.setUserCredentials(userCredentials);
        this.sessionService.setSessionInfo(userCredentials.webSessionId, userCredentials.userProfileId);
        return userCredentials;
      })
    ).subscribe(async (userCreds) => {
      // sessionStorage.setItem('refresh', JSON.stringify(false));
      sessionStorage.setItem('previousDisableState', JSON.stringify([]));
      let credentials = userCreds.getCredentials();

      this.service.setCredentials(credentials);
      let checkLegacyAccountDisabled = [];
      let nonEnvironmentCredentials = [];
      let credApplicationName = [];
      credentials.forEach(cred => {
        cred.disabled = false;
        cred.locked = false;
        console.log("Chckng creds in tokenComponent", cred)
        // get the application related information
        if (cred) {
          let appName = cred.applicationName;
          if (!appName) {
            appName = cred.legacyApplicationId;
          }
          credApplicationName.push(appName)

          let legacyAccountName = cred.accountLogin;
          legacyAccountName = this.removeLastIndex(legacyAccountName).toLowerCase();
          const appInfo = this.appService.getAppInfo(appName);
          cred.addApplicationAttributes(appInfo);
        }
      });

      if(credApplicationName.length > 0) {
        console.log("Checking Application Name for SMBUSer", credApplicationName,credentials)
        const chckAppName = credApplicationName.every(appName => appName === 'CASHFLOW')
        if(chckAppName === true) {
          sessionStorage.setItem('isSMBUser', 'true');
        } else {
          sessionStorage.setItem('isSMBUser', 'false')
        }
      }
      this.appService.initDashboard();

      const userInfo = userCreds.getUserInfo();
      this.service.setUserInfo(userInfo);
      this.service.credentialsDataSource.next({
        credentials: credentials,
        userInfo: userInfo,
        error: undefined
      });
      this.getDisabledCredsData(credentials, userInfo);

      if (credentials.length !== 0) {
        // let disabledState = [];
        forkJoin(checkLegacyAccountDisabled).pipe(catchError(err => of(err))).subscribe(res => {
          sessionStorage.setItem('previousDisableState', JSON.stringify([]));
          nonEnvironmentCredentials = nonEnvironmentCredentials.map((cred, index) => {
            // if (!environment.nonOUDApps.includes(cred.legacyApplicationId)) {
            if (this.appService.getNonOUDAppsList()?.includes(cred.legacyApplicationId)) {
              if (
                this.getNested(
                  res[index],
                  'data',
                  'data',
                  'json_data',
                  'user_disabled'
                ) || this.getNested(
                  res[index],
                  'data',
                  'data',
                  'json_data',
                  'user_locked'
                )
              ) {
                cred.disabled = res[index].data.data.json_data.user_disabled;
                cred.locked = res[index].data.data.json_data.user_locked;
              } else {
                cred.disabled = false;
                cred.locked = false;
              }
            }

            return cred;
          });
          const userInfo = userCreds.getUserInfo();
          this.service.setUserInfo(userCreds.getUserInfo());
          credentials = credentials.map(eachCred => {

            const findCred = nonEnvironmentCredentials.find(nonEnv =>
              nonEnv.userId === eachCred.userId && nonEnv.compId === eachCred.compId && nonEnv.displayName === eachCred.displayName)
            if (findCred) {
              eachCred.disabled = findCred.disabled;
              eachCred.locked = findCred.locked;
            }
            return eachCred;
          });
          if (userInfo) {
            this.service.credentialsDataSource.next({
              credentials: credentials,
              userInfo: userInfo,
              error: undefined
            });
          } else {
            this.sessionService.setSSOTokenValidation("Error " + userCredentials.errorCode + " occurred retrieving credentials.  Please try again or contact support.");
            this.router.navigate(['uportal/login']);
          }
        });
      } else {
        // For All environment apps without forkjoin calls for disable state
        const userInfo = userCreds.getUserInfo();
        this.service.setUserInfo(userCreds.getUserInfo());
        this.service.credentialsDataSource.next({
          credentials: credentials,
          userInfo: userInfo,
          error: undefined
        });
      }

      this.sessionService.setSSOTokenValidation(undefined);
      this.sessionService.setAuthenticated(true);
      //Check mobile url and make a call to check digital butler
      //else redirect to dashboard view for desktop
      if (this.sessionService.isButlerApp()) {
        const userInfo = userCreds.getUserInfo();
        //call to salesforce portal to check persistent id
        this.universalId = userInfo.universalId;
        this.getPersistenceIDsForMobileButler(userInfo.universalId);
      } else {
        this.router.navigate(['dashboard']);
      }
    },
      err => {
        console.error('Error retrieving Credentials:', err);
        this.sessionService.setSSOTokenValidation(err);
        this.router.navigate(['uportal/login']);
      }
    );
  }

  getDisabledCredsData(credentials, userInfo) {
    const universalId = sessionStorage.getItem('universalId')
    this.service.checkLegacyAccountDisabledStatus().subscribe(res => {
      let disabledCredentials = res;
      console.log('checking for universalID token', universalId)
      sessionStorage.setItem("linkedCredentialInfo", JSON.stringify(res));
      if(disabledCredentials.hasOwnProperty('universalIdDisabled')){
        this.service.setUserDisabledStatus(disabledCredentials['universalIdDisabled'])
      }
      credentials.forEach(cred => {
        if (disabledCredentials) {
          let legacy_creds = disabledCredentials[cred.accountLogin]
          if (!legacy_creds || legacy_creds instanceof String || typeof legacy_creds === 'string') {
            cred.disabled = false;
            cred.locked = false;
          } else {
            cred.disabled = legacy_creds.user_disabled;
            cred.locked = legacy_creds.user_locked;
          }
        }
      })
      this.service.credentialsDataSource.next({
        credentials: credentials,
        userInfo: userInfo,
        error: undefined
      });
    }, (error) => {
      console.log("error", error)
    });
  }


  // async getAccountDisabledStatus(){
  //   let disabledCreds = null;
  //   this.service.checkLegacyAccountDisabledStatus().subscribe(res => {
  //     if(res.hasOwnProperty('universalIdDisabled')){
  //       this.service.setUserDisabledStatus(res['universalIdDisabled'])
  //     }
  //     disabledCreds = res;
  //   }, (error) => {
  //     console.log("error", error)
  //   });
  //   return disabledCreds;
  // }

  removeLastIndex(legacyAccount: string) {
    let index = legacyAccount.lastIndexOf("_");
    return legacyAccount.slice(0, index);
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  //auth/salesportalservice/userIdMaps/userID
  //servicecase
  getPersistenceIDsForMobileButler(universalId) {
    const url = environment.univ_getSalePortalCompanyDetails + '/' + universalId;
    this.https.get<any>(url).subscribe(data => {
      this.sessionService.setUserIdMaps({ userIdMaps: data });
      data.forEach(x => {
        this.spinnerService.setText('Please wait...');
        if ((x.companyName !== null) && (x.prsistentId !== null) && (x.prsistentId !== '') && (x.companyName !== '')) {
          if (this.userCompanyNames.add(x.companyName)) {
            this.userCompanyNamesMap.set(x.companyName, x.prsistentId);
          }
        }
      });
      //Redirect to digital butler
      if (this.userCompanyNames.size > 1) {
        this.router.navigate(['butler']);
      } else if (this.userCompanyNames.size === 1) {
        //Ping drop call to digital butler landing page...
        this.pingDropLogin('One', this.userCompanyNames);
      } else {
        this.openUrl(environment.univ_helpCenter.butlerUrl);
      }
    },
      (error) => {
        this.sessionService.setErrorDescription({ description: 'Sales Portal service error', code: error.code });
        this.router.navigate(['unavailable']);
      });
  }

  openUrl(url) {
    if (url !== undefined) {
      (window.location.href = url) || window.open(url, '_blank');
    }
  }

  pingDropLogin(noOfLinkedCrds, compNames) {
    const sfTargetPath = environment.univ_helpCenter.butlerUrl;
    let companyName = '';

    if (noOfLinkedCrds == 'Many') {
      companyName = compNames['key'];
    } else {
      for (let item of compNames) {
        companyName = item;
      }
    }
    const persistentId = this.userCompanyNamesMap.get(companyName);
    console.log(('company and persistent'), companyName, persistentId);
    this.transmitService.authenticate(companyName, undefined, persistentId, this.universalId,
      undefined, undefined, 'sfdc_ping_dropoff', '', '', sfTargetPath, '', '')
      .then((res) => {
        const tokenInfo = this.getDecodedAccessToken(res._token);
        if (tokenInfo === undefined || tokenInfo === null || tokenInfo === '') {
          this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
          return;
        }

        const ping_login_url = tokenInfo['ping_sso_url'];
        if (ping_login_url === undefined || ping_login_url === null || ping_login_url === '') {
          this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
          return;
        }
        if (ping_login_url) {
          (window.location.href = ping_login_url) || window.open(ping_login_url, '_blank');
        }
      })
      .catch((error) => {
        this.sessionService.setErrorDescription({ description: 'Ping dropoff error', code: error.code });
        this.router.navigate(['unavailable']);
      });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

}
