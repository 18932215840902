import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { PasswordAuthenticatorService } from 'src/app/transmit/beta/password-authenticator.service';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from '../../../core/service/universalSession.service';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss']
})
export class EnterOtpComponent implements OnInit, AfterViewInit {

  submitted = false;
  loading = false;
  appName = 'achieve';
  model = {};
  returnUrl: string;
  resetEnabled = false;
  passcodeForm: UntypedFormGroup;
  modalText = '';
  showModal = false;
  private pageName = 'otp';
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';
  private previousPage = 'temppass';  // default for Analytics
  next = 'confirmation';  // remove next when integrating transmit

  public contactMessage: any = environment.contactMessage;
  public isAndroid = false;
  public phoneNumber1: string;
  public phoneNumber2: string;

  public targetSelected: string;

  public displayOTPErrorMessage = false;
  public displayOTPFailedErrorMessage = false;

  public onSubmitCode: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public resendCode: () => void;
  public changeMode: () => void;
  public onCancel: () => void;
  public universalLoginServiceCase = environment.universalLoginServiceCase;

  @ViewChild('passcode', { read: ElementRef }) firstField: ElementRef;
  public checkTLIComponent: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder, private utils: SsoUtils,
              private alertService: AlertService,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService,
              private transmitUtils: UniversalTransmitUtils,
              private spinnerService: SpinnerService) {
    this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngOnInit() {
    const data = this.sessionService.getSessionData(this.pageName);
    this.pageName = data.pageName;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    if (this.route.snapshot.routeConfig.path === 'trouble') {
      this.checkTLIComponent = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.firstField.nativeElement.focus();
    }
    this.resetAnalytics();
  }

  resetAnalytics() {
    const prevPage = this.sessionService.getPreviousPageName();
    if ( prevPage !== '' || undefined){
      this.previousPage = prevPage;
    }
    const analyticsJson = {
      cbpgType: this.previousPage,
      cbaction: 'view',
      cbpgFunction: 'otpverification-enterotp',
      cbpgSubFunction: '',
      cbpgName: 'view',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  createForm(){
    this.passcodeForm = this.formBuilder.group({
      passcode: ['', Validators.required],
      remember: ['']
    });
  }

  get f() {
    return this.passcodeForm.controls;
  }

  onSubmit(formData) {
    this.alertService.hide();
    this.hideOTPErrors();
    this.submitted = true;

    if (this.passcodeForm.invalid) {
      return;
    } else {
      const passcode = formData.passcode;
      const input = com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(passcode);
      const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(input);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);

      if (this.onSubmitCode) {
        this.onSubmitCode(response);
      }
    }
  }

  selectMode() {
    this.changeMode();
  }

  getCode() {
    this.resendCode();
  }

  back(evt){
    evt.preventDefault();
    this.onCancel();
  }

  public getTarget(target) {
    this.targetSelected = target._description;
  }

  showOTPLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getOTPLockedErrorMessage());
  }

  public showOTPErrorMessage(){
    this.displayOTPErrorMessage = true;
  }

  public showOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = true;
  }

  public showServiceUnavailableError() {
    this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  }

  hideOTPErrorMessage() {
    this.displayOTPErrorMessage = false;
  }

  public hideOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = false;
  }

  public hideOTPErrors() {
    this.displayOTPErrorMessage = false;
    this.displayOTPFailedErrorMessage = false;
  }

}
